// Roboto
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

// Roboto Slab
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Roboto Mono
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

// Open Sans
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

// Poppins
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Unica One
@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");

// Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Noto Sans
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Nunito
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	height: 100%;
	padding: 0.01px;

	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body {
	height: 100%;
	padding: 0.01px;

	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

#root {
	height: 100%;
	padding: 0.01px;

	margin: 0px;
	padding: 0px;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;
}

*,
*::after,
*::before {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
	
}